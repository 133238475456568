import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { getNameWithIndex } from "../statistics-util/helpers";
import "./RelFrequencyChart.css";

/**
 * Component to render a brush barchart displaying the relative frequencies of each letter using the provided keys as data keys.
 * @param {Array<object>} statTextRelFrequencies should look like this:
 *    [{ letter: "a", Rel. Häufigkeit: 0.x, Rel. Häufigkeit DE: 0.x, ... }, ...]
 * @param {Array<string>} keys is an array of fields to render bars for
 * @param {Array<string>} colours Colours to use for bars and active legend items
 * @param {Array<string>} coloursDimmed Colours to use for inactive legend items
 */
function RelFrequencyChart({
  statTextRelFrequencies,
  keys,
  colours,
  coloursDimmed,
}) {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => {
    window.onresize = updateSize;
  }, []);

  const averageRelFreq =
    statTextRelFrequencies.reduce((val, elem) => val + elem.freq, 0.0) /
    statTextRelFrequencies.length;

  // All bars are active on first component render
  const [barToggle, setBarToggle] = useState(
    keys.reduce((o, key) => ({ ...o, [key]: true }), {})
  );

  useEffect(() => {
    keys.forEach((k) => {
      setBarToggle((b) => {
        // Ensure Rel. Häufigkeit T0 isn't dimmed when first shown
        if (b[k] === undefined && k === getNameWithIndex(0)) {
          return { ...b, [k]: true };
        }
        return b;
      });
    });
  }, [keys]);

  return (
    /*  Container has static height --> Looks oversized on small devices
        Setting to 100% collapses chart. Could set height dynamically
        using viewport dimensions in size variable */
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={300}
        height={400}
        data={statTextRelFrequencies}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="letter" />
        <YAxis type="number" domain={[0.0, 0.2]} scale="linear" tickCount={5} />
        <Tooltip
          labelFormatter={(l) => (
            <span className="labelP">
              {/* Index displayed to aid in computing alphabet offsets */}
              Index {l.charCodeAt() - "a".charCodeAt()} im Alphabet
            </span>
          )}
          formatter={(value) => value.toFixed(2)}
        />
        <Legend
          verticalAlign="top"
          align="right"
          iconType="square"
          wrapperStyle={{ lineHeight: "40px" }}
          payload={keys.map((key, index) => {
            return {
              value: key,
              type: "square",
              color: barToggle[key] ? colours[index] : coloursDimmed[index],
            };
          })}
          onClick={onLegendClick(barToggle, setBarToggle)}
        />
        <ReferenceLine y={averageRelFreq} stroke="grey" />
        {/* Display brush for large devices or smaller to help in focusing parts of the chart */}
        {size.x < 1200 && (
          <Brush
            dataKey="letter"
            height={15}
            stroke="black"
            startIndex={0}
            endIndex={getBrushIndex(size.x, statTextRelFrequencies.length)}
          />
        )}
        {keys.map((key, index) => {
          return (
            barToggle[key] && (
              <Bar key={key} dataKey={key} name={key} fill={colours[index]} />
            )
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

RelFrequencyChart.propTypes = {
  statTextRelFrequencies: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colours: PropTypes.arrayOf(PropTypes.string).isRequired,
  coloursDimmed: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Handler functions
function onLegendClick(barToggle, setBarToggle) {
  return (legendItem) => {
    setBarToggle({
      ...barToggle,
      [legendItem.value]: !barToggle[legendItem.value],
    });
  };
}

// helper functions

function getBrushIndex(windowWidth, length) {
  if (windowWidth >= 1200) {
    // large devices
    return length - 1;
  }
  if (windowWidth >= 992) {
    // medium devices
    return 20;
  }
  if (windowWidth >= 768) {
    // small devices
    return 10;
  }
  // extra small devices
  return 2;
}

export default RelFrequencyChart;
