import React from "react";
import PropTypes from "prop-types";
import FCChart from "./FCChart";

import { statRelativeFrequency } from "../statistics-util/statistics-util";

/**
 * Renders an fc bar chart for a specific language
 * @param {string} language string representation of language, see proptypes
 * @param {Array<string>} colour used to render bar chart
 */
function FCChartForLanguage({ language, colour }) {
  let fcVal = 0.0;
  try {
    fcVal = statRelativeFrequency(language).reduce(
      (sum, entry) => (entry.freq - 1 / 26) ** 2 + sum,
      0.0
    );
  } catch (e) {
    fcVal = 0.0;
  }

  return <FCChart fcData={fcVal} colours={[colour]} />;
}

FCChartForLanguage.propTypes = {
  language: PropTypes.oneOf(["DE", "FR", "EN"]).isRequired,
  colour: PropTypes.string.isRequired,
};

export default FCChartForLanguage;
