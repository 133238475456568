import React from "react";
import PropTypes from "prop-types";
import { Form, InputGroup, Button } from "react-bootstrap";

/**
 * Text-to-be-analyzed submission form
 * Only used in Statistics - was exported here to reduce cognitive complexity
 */
function TextInput({
  handleTextSubmit,
  handleTextChange,
  validated,
  inputText,
  maxInputLength,
}) {
  return (
    <Form noValidate validated={validated} onSubmit={handleTextSubmit}>
      <Form.Group controlId="textEingabeFeld">
        <Form.Label srOnly>Eingabetext</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            required
            as="textarea"
            maxLength={maxInputLength}
            placeholder="Bitte gib hier deinen Text ein."
            onChange={handleTextChange}
          />
          <InputGroup.Append>
            <Button size="sm" variant="dark" type="submit">
              Submit
            </Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid" tooltip>
            Bitte gebe Text ein.
          </Form.Control.Feedback>
        </InputGroup>
        <Form.Text id="textHelperBlock" muted>
          <span className="helptext">
            Der Text darf 1-{maxInputLength} Zeichen lang sein. Bitte beachte,
            dass ä, ö, ü und Satzzeichen in der Statistik nicht berücksichtigt
            werden.
          </span>
          <span className="charRemaining">
            {inputText.length}/{maxInputLength}
          </span>
        </Form.Text>
      </Form.Group>
    </Form>
  );
}

TextInput.propTypes = {
  handleTextSubmit: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  inputText: PropTypes.string.isRequired,
  maxInputLength: PropTypes.number.isRequired,
};

export default TextInput;
