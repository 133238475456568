import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Form, Overlay, Tooltip } from "react-bootstrap";

/**
 * Keylength input field, mainly used for FCChart
 */
function KeyLengthInput({
  handleKeyLengthChange,
  minKeyLength,
  maxKeyLength,
  displayError,
}) {
  const overlayTarget = useRef();
  return (
    <>
      <Form.Control
        size="md"
        type="number"
        min={minKeyLength}
        max={maxKeyLength}
        ref={overlayTarget}
        placeholder="Schlüssellänge"
        onChange={handleKeyLengthChange}
      />
      <Overlay
        target={overlayTarget.current}
        show={displayError}
        placement="bottom"
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Bitte gebe eine Zahl zwischen {minKeyLength} und {maxKeyLength} ein
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

KeyLengthInput.propTypes = {
  handleKeyLengthChange: PropTypes.func.isRequired,
  minKeyLength: PropTypes.number.isRequired,
  maxKeyLength: PropTypes.number.isRequired,
  displayError: PropTypes.bool.isRequired,
};

export default KeyLengthInput;
