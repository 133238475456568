import { lazy } from "react";

// -------------- Exercise Components --------------
const TranspositionExerciseEasy = lazy(() =>
  import("./TranspositionExercise/TranspositionExerciseEasy")
);
const TranspositionExerciseMedium = lazy(() =>
  import("./TranspositionExercise/TranspositionExerciseMedium")
);

const Scytale = lazy(() => import("./Scytale/Scytale"));

const CaesarEasy = lazy(() => import("./Caesar/CaesarEasy"));
const CaesarMedium = lazy(() => import("./Caesar/CaesarMedium"));
const CaesarHard = lazy(() => import("./Caesar/CaesarHard"));
const MaskedCaesar = lazy(() => import("./Caesar/MaskedCaesar"));

const CaesarVariation = lazy(() => import("./CaesarVariation/CaesarVariation"));

const VigenereEasy = lazy(() => import("./Vigenere/VigenereEasy"));
const VigenereMedium = lazy(() => import("./Vigenere/VigenereMedium"));
const VigenereHard = lazy(() => import("./Vigenere/VigenereHard"));

// -------------- Exercise categories --------------
const transposition = "Transposition";
const monoAlph = "Monoalphabetische Verschlüsselung";
const polyAlph = "Polyalphabetische Verschlüsselung";

/**
 * An array that contains one object per exercise.
 * Each object must have the following fields:
 *  - title: The unique title of the exercise
 *  - difficulty: A number in range [1-5] representing the relative difficulty of the exercise
 *  - category: One of the categories listed above
 *  - path: The unique URL extension to use to navigate to the exercise
 *  - redirect: The redirect link to use in the success alert. This should usually either lead to the next exercise to solve or back to the homepage (i.e. "/")
 *  - component: The component to use to render the exercise. It should be included as a lazy import above to avoid loading all components everytime the homepage is loaded
 */
const exercises = [
  {
    title: "Transposition I",
    difficulty: 1,
    category: transposition,
    path: "/transposition_I",
    redirect: "/transposition_II",
    component: TranspositionExerciseEasy,
  },
  {
    title: "Transposition II",
    difficulty: 1,
    category: transposition,
    path: "/transposition_II",
    redirect: "/",
    component: TranspositionExerciseMedium,
  },
  {
    title: "Skytale",
    difficulty: 1,
    category: transposition,
    path: "/skytale",
    redirect: "/",
    component: Scytale,
  },
  {
    title: "Caesar Verschlüsselung I",
    difficulty: 1,
    category: monoAlph,
    path: "/caesar_easy",
    redirect: "/caesar_medium",
    component: CaesarEasy,
  },
  {
    title: "Caesar Verschlüsselung II",
    difficulty: 2,
    category: monoAlph,
    path: "/caesar_medium",
    redirect: "/",
    component: CaesarMedium,
  },
  {
    title: "Caesar Verschlüsselung III",
    difficulty: 3,
    category: monoAlph,
    path: "/caesar_hard",
    redirect: "/",
    component: CaesarHard,
  },
  {
    title: "Caesar Variation",
    difficulty: 3,
    category: polyAlph,
    path: "/caesar_variation",
    redirect: "/",
    component: CaesarVariation,
  },
  {
    title: "Maskiert-Caesar Verschlüsselung I",
    difficulty: 3,
    category: polyAlph,
    path: "/maskedCaesar_easy",
    redirect: "/",
    component: MaskedCaesar,
  },
  {
    title: "Vigenère I",
    difficulty: 2,
    category: polyAlph,
    path: "/specialist_easy",
    redirect: "/specialist_medium",
    component: VigenereEasy,
  },
  {
    title: "Vigenère II",
    difficulty: 3,
    category: polyAlph,
    path: "/specialist_medium",
    redirect: "/",
    component: VigenereMedium,
  },
  {
    title: "Vigenère III",
    difficulty: 4,
    category: polyAlph,
    path: "/specialist_hard",
    redirect: "/",
    component: VigenereHard,
  },
];

export default exercises;
