import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button } from "react-bootstrap";
import { encryptTextWithKey } from "../Assignments/assignments-util/encryption-util";
import { cleanText } from "../Assignments/assignments-util/helpers";

/**
 * Renders plaintext and key input fields and a ciphertext output to allow the
 * user to try out different encryption methods
 * @param {function} setTxt function to pass the computed cipherText to parent
 */
function EncryptionExample({ setTxt }) {
  const [inputLength, setInputLength] = useState(0);
  const [validated, setValidated] = useState(false);
  const maxInputLength = 1024;
  const initialCipherTxt = "Hier wird dein Geheimtext stehen!";
  const clearTxtInputID = "clearTxtInput";
  const keyInputID = "keyInput";
  const cipherTxtID = "cipherOutput";

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit(
        setValidated,
        clearTxtInputID,
        keyInputID,
        cipherTxtID,
        setTxt
      )}
    >
      <Row>
        <Col sm={12} md={5}>
          <Form.Group controlId={clearTxtInputID}>
            <Form.Label>Klartext</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              maxLength={maxInputLength}
              placeholder="Bitte gebe hier deinen Klartext ein."
              onChange={handleTextChange(setValidated, setInputLength)}
            />
            <Form.Text id="textHelperBlock" muted>
              <span className="helptext">
                Der Text darf 1-{maxInputLength} Zeichen lang sein.
              </span>
              <span className="charRemaining">
                {inputLength}/{maxInputLength}
              </span>
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={keyInputID}>
            <Form.Label>Schlüssel</Form.Label>
            <Form.Control
              type="text"
              minLength={1}
              maxLength={5}
              pattern="[a-zA-Z]{1,5}"
              placeholder="Schlüssel"
              onChange={handleKeyChange(setValidated)}
            />
            <Button
              type="submit"
              variant="dark"
              className="btn-block align-self-end"
              style={{ marginTop: "5px" }}
            >
              Verschlüsseln
            </Button>
            <Form.Control.Feedback type="invalid">
              Bitte verwende nur Buchstaben zwischen a und z
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} md={5}>
          {/* Ciphertext field is constructed as disabled form element to ensure identical sizing as plaintext field */}
          <Form.Group controlId={cipherTxtID}>
            <Form.Label>Geheimtext</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              plaintext
              readOnly
              defaultValue={initialCipherTxt}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

EncryptionExample.propTypes = {
  setTxt: PropTypes.func,
};

EncryptionExample.defaultProps = {
  setTxt: (t) => t,
};

// handler functions

function handleTextChange(setValidated, setInputLength) {
  return (event) => {
    const val = event.currentTarget.value;
    setInputLength(val.length);
    setValidated(false);
  };
}

function handleKeyChange(setValidated) {
  return () => {
    setValidated(false);
  };
}
function handleSubmit(
  setValidated,
  clearTxtInputID,
  keyInputID,
  cipherTextID,
  setTxt
) {
  return (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const clearTxt = form.elements[clearTxtInputID].value;
      const key = form.elements[keyInputID].value;
      const cipherTxt = cleanText(encryptTextWithKey(clearTxt, key));
      form.elements[cipherTextID].value = cipherTxt;
      setTxt(cipherTxt);
    }
    setValidated(true);
  };
}

export default EncryptionExample;
