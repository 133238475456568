import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "react-bootstrap";

import {
  mergeByProperty,
  getLangStatsWithRenamedFields,
  getRelFreqWithRenamedFields,
} from "./statistics-util/helpers";
import TextInput from "./TextInput/TextInput";
import KeyLengthInput from "./KeyLengthInput/KeyLengthInput";
import RelFrequencyChart from "./RelFrequencyChart/RelFrequencyChart";
import FCChartForText from "./FCChart/FCChartForText";

import "./Statistics.css";

/**
 * Statistical component to be rendered as a help tool for assignments
 * or in isolation. Shows a relative frequency distribution chart by default
 * and can be configured to show a text input field and/or an FC Chart.
 * Possible additional features to expand on include:
 * - Options to switch from single letters to bi- or trigrams
 * - Options to switch from/to alphabetical or magnitude (default) ordering
 * - Tooltip for FCChart showing info on language-specific values
 *
 * @param {string} inputTxt if not set a text input field is shown
 * @param {number} keyLen can be set in addition to inputTxt, relFreqChart will
 * show additional statistics accordingly
 * @param {number} minKeyLength used for tooltip on FCChart
 * @param {number} maxKeyLength used for tooltip on FCChart
 * @param {boolean} showFCGraph
 * @param {Array<string>} languagesToShow Currently only supports "DE", "FR" and/or "EN"
 */
function Statistics({
  inputTxt,
  keyLen,
  minKeyLength,
  maxKeyLength,
  showFCGraph,
  languagesToShow,
}) {
  const [inputText, setInputText] = useState("");
  const [keyLength, setKeyLength] = useState(keyLen);
  const [statText, setStatText] = useState(inputTxt);
  const [relFreqs, setRelFreqs] = useState(
    languagesToShow.reduce(
      (currRelFreqs, language) =>
        mergeByProperty(getLangStatsWithRenamedFields(language), currRelFreqs),
      getRelFreqWithRenamedFields(inputTxt, keyLen === 0 ? 1 : keyLen)
    )
  );
  const [validated, setValidated] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const maxInputLength = 1024; // max input length of text field
  const colours = [
    "#E42256",
    "#FEC84D",
    "#00B1B0",
    "#FF8370",
    "#BCFF70",
    "#FF00FF",
    "#B8A1FF",
    "#ECCEB0",
  ];
  const coloursDimmed = [
    "#f7bbcb",
    "#ffe7b3",
    "#b3ffff",
    "#ffbdb3",
    "#dbffb3",
    "#ffb3ff",
    "#e3d9ff",
    "#f7ebdf",
  ];

  const keysToRender = Object.keys(relFreqs[0]).filter((el) => el !== "letter");

  return (
    <>
      {/* If no inputTxt is given we show the text input field */}
      {inputTxt === "" && (
        <TextInput
          handleTextSubmit={handleTextSubmit(
            inputText,
            relFreqs,
            keyLength,
            setStatText,
            setValidated,
            setRelFreqs
          )}
          handleTextChange={handleTextChange(setInputText, setValidated)}
          validated={validated}
          inputText={inputText}
          maxInputLength={maxInputLength}
        />
      )}
      {/* We always show the relative frequency chart */}
      <RelFrequencyChart
        statTextRelFrequencies={relFreqs}
        keys={keysToRender}
        colours={colours}
        coloursDimmed={coloursDimmed}
      />
      {/* Show FC chart based on showFCGraph prop */}
      {showFCGraph && (
        <Form>
          <Form.Group as={Row} controlId="fc">
            <Form.Label column srOnly>
              Schlüssellänge
            </Form.Label>
            <Col>
              <KeyLengthInput
                handleKeyLengthChange={handleKeyLengthChange(
                  relFreqs,
                  statText,
                  setKeyLength,
                  setRelFreqs,
                  setDisplayError
                )}
                minKeyLength={minKeyLength}
                maxKeyLength={maxKeyLength}
                displayError={displayError}
              />
            </Col>
            <Col>
              <FCChartForText
                statText={statText}
                keyLength={keyLength}
                colours={colours}
              />
            </Col>
          </Form.Group>
        </Form>
      )}
      <hr className="solid" />
    </>
  );
}

Statistics.propTypes = {
  inputTxt: PropTypes.string,
  keyLen: PropTypes.number,
  minKeyLength: PropTypes.number,
  maxKeyLength: PropTypes.number,
  showFCGraph: PropTypes.bool,
  languagesToShow: PropTypes.arrayOf(PropTypes.oneOf(["DE", "FR", "EN"])),
};

Statistics.defaultProps = {
  inputTxt: "",
  keyLen: 0,
  minKeyLength: 1,
  maxKeyLength: 5,
  showFCGraph: false,
  languagesToShow: ["DE"],
};

// Submission handlers for readability
function handleTextChange(setInputText, setValidated) {
  return (event) => {
    setInputText(event.target.value);
    setValidated(false);
    event.preventDefault();
  };
}

function handleTextSubmit(
  inputText,
  relFreqs,
  keyLength,
  setStatText,
  setValidated,
  setRelFreqs
) {
  return (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setStatText(inputText);
      setRelFreqs(
        mergeByProperty(
          relFreqs,
          getRelFreqWithRenamedFields(inputText, keyLength)
        )
      );
    }
    setValidated(true);
    event.preventDefault();
  };
}

function handleKeyLengthChange(
  relFreqs,
  statTxt,
  setKeyLength,
  setRelFreqs,
  setDisplayError
) {
  return (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      setDisplayError(false);
      if (event.target.value !== "") {
        setRelFreqs(
          mergeByProperty(
            relFreqs,
            getRelFreqWithRenamedFields(statTxt, event.target.value)
          )
        );
        setKeyLength(parseInt(event.target.value, 10));
      }
    } else {
      setDisplayError(true);
    }
  };
}

export default Statistics;
