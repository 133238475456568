import React from "react";
import PropTypes from "prop-types";
import { Container, Breadcrumb, BreadcrumbItem } from "react-bootstrap";

import exercises from "../Assignments/exercises";

import "./Exercise.css";

/**
 * Renders an assignment inside a fluid container, includes breadcrumb for navigational purposes
 * TODO: Could probably generalize to serve as a wrapper around all web app contents
 * @param {string} title title of the exercise to render, used as identifier in exercise file
 * @param {React.element} children Assignment component to be rendered
 */
function Exercise({ title, children }) {
  const metadata = exercises.find((e) => e.title === title);
  return (
    <div className="exercise">
      <Container fluid="lg">
        <Breadcrumb>
          <BreadcrumbItem href="/">Home</BreadcrumbItem>
          <BreadcrumbItem active>{metadata.category}</BreadcrumbItem>
          <BreadcrumbItem active>{metadata.title}</BreadcrumbItem>
        </Breadcrumb>
        {children}
      </Container>
    </div>
  );
}

Exercise.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Exercise;
