/**
 * Translates ciphertext character to plaintext character
 * Note: Keychar 'a' corresponds to modular subtraction of 0
 * @param {String} cipherChar
 * @param {String} keyChar UNcapitalized key character
 * @returns {String} cleartext character
 */
export function getClearTextChar(cipherChar, keyChar) {
  const keyValue = keyChar.charCodeAt() - "a".charCodeAt();
  let clearCharVal = cipherChar.charCodeAt();
  const diff = cipherChar.charCodeAt() - keyValue;
  if (/[A-Z]/.test(cipherChar)) {
    if (diff < "A".charCodeAt()) {
      clearCharVal = "Z".charCodeAt() - ("A".charCodeAt() - diff) + 1;
    } else {
      clearCharVal = diff;
    }
  } else if (/[a-z]/.test(cipherChar)) {
    if (diff < "a".charCodeAt()) {
      clearCharVal = "z".charCodeAt() - ("a".charCodeAt() - diff) + 1;
    } else {
      clearCharVal = diff;
    }
  }
  return String.fromCharCode(clearCharVal);
}

/**
 * Translates plaintext character to ciphertext character
 * Note: Keychar 'a' corresponds to modular addition of 0
 * @param {string} clearChar
 * @param {string} keyChar UNcapitalized key character
 * @returns {string} ciphertext character
 */
export function getCipherTextChar(clearChar, keyChar) {
  const keyValue = keyChar.toLowerCase().charCodeAt() - "a".charCodeAt();
  let cipherTextChar = clearChar.charCodeAt();
  const diff = clearChar.charCodeAt() + keyValue;
  if (/[A-Z]/.test(clearChar)) {
    if (diff > "Z".charCodeAt()) {
      cipherTextChar = "A".charCodeAt() + (diff - "Z".charCodeAt()) - 1;
    } else {
      cipherTextChar = diff;
    }
  } else if (/[a-z]/.test(clearChar)) {
    if (diff > "z".charCodeAt()) {
      cipherTextChar = "a".charCodeAt() + (diff - "z".charCodeAt()) - 1;
    } else {
      cipherTextChar = diff;
    }
  }
  return String.fromCharCode(cipherTextChar);
}

/**
 * Translates plaintext to ciphertext
 * Note: Keychar 'a' corresponds to modular addition of 0
 * @param {String} cleartxt
 * @param {String} key UNcapitalized key string
 * @returns {String} ciphertext
 */
export function encryptTextWithKey(cleartxt, key) {
  let ciphertxt = "";
  let i = 0;
  [...cleartxt].forEach((clearChar) => {
    ciphertxt += getCipherTextChar(clearChar, key.charAt(i % key.length));
    if (!/\s/.test(clearChar)) {
      i += 1;
    }
  });
  return ciphertxt;
}

/**
 * Translates ciphertext to plaintext
 * Note: Keychar 'a' corresponds to modular subtraction of 0
 * @param {String} ciphertxt
 * @param {String} key UNcapitalized key string
 * @returns {String} plaintext
 */
export function decryptTextWithKey(ciphertxt, key) {
  let cleartxt = "";
  let i = 0;
  [...ciphertxt].forEach((cipherChar) => {
    cleartxt += getClearTextChar(cipherChar, key.charAt(i % key.length));
    if (!/\s/.test(cipherChar)) {
      i += 1;
    }
  });
  return cleartxt;
}
