import React from "react";
import PropTypes from "prop-types";
import { fcForKeyLength } from "../statistics-util/statistics-util";
import FCChart from "./FCChart";

/**
 * Renders an fc chart for a given text and keylength
 * @param {string} statText text for which to compute fc value
 * @param {number} keyLength keylength to use in computation
 * @param {Array<string>} colours uses colours[0] to render chart
 */
function FCChartForText({ statText, keyLength, colours }) {
  let fcVal = 0.0;
  try {
    fcVal = fcForKeyLength(keyLength, statText);
  } catch (e) {
    // Simply uses 0.0 as fc value on invalid inputs
    fcVal = 0.0;
  }
  return <FCChart fcData={fcVal} colours={colours} />;
}

FCChartForText.propTypes = {
  statText: PropTypes.string.isRequired,
  keyLength: PropTypes.number.isRequired,
  colours: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FCChartForText;
