// React
import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// React-Bootstrap
import { Spinner } from "react-bootstrap";

// Components
import exercises from "../Assignments/exercises";
import Navigation from "../Navigation/Navigation";
import Exercise from "../Exercise/Exercise";
import Theory from "../Theory/Theory";
import CustomNavbar from "./CustomNavbar/CustomNavbar";

import "./App.css";

// Global constants
const homeURL = "/";
const theoryURL = "/theory";

/**
 * Implements two main functionalities:
 *  1. Displays the navigation bar --> Implemented in CustomNavBar
 *  2- Handles all routing --> BrowserRouter
 */
function App() {
  return (
    <div>
      {/* Custom navigation bar */}
      <CustomNavbar
        homeURL={homeURL}
        exerciseURL={homeURL}
        theoryURL={theoryURL}
      />

      {/* Routing logic */}
      <BrowserRouter>
        <Route exact path={homeURL}>
          <Navigation exercises={exercises} />
        </Route>
        <Route exact path={theoryURL}>
          <Theory />
        </Route>

        {exercises.map((ex) => {
          const Comp = ex.component;
          return (
            <Route key={ex.title} exact path={ex.path}>
              <Exercise title={ex.title}>
                <Suspense
                  fallback={
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  }
                >
                  <Comp redirect={ex.redirect} />
                </Suspense>
              </Exercise>
            </Route>
          );
        })}
      </BrowserRouter>
    </div>
  );
}

export default App;
