/**
 * Picks a random property from an object and returns its value
 * @param {Object} obj
 * @returns {any}
 */
export function randomProperty(obj) {
  const keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
}

/**
 * generates a random key string of the given length
 * @param {number} length
 * @returns {string}
 */
export function randomKey(length) {
  let str = "";
  const alph = getAlphabet();
  for (let i = 0; i < length; i += 1) {
    str += alph[Math.round(Math.random() * alph.length)];
  }
  return str;
}

/**
 * @returns {Array<string>} an array containing all 26 letters of the alphabet, in alphabetical order
 */
export function getAlphabet() {
  /* eslint-disable func-names */
  const iterate = function* (a, b) {
    for (let i = a; i <= b; i += 1) {
      yield i;
    }
  };
  return [...iterate("a".charCodeAt(), "z".charCodeAt())].map((n) =>
    String.fromCharCode(n)
  );
}

/**
 * Removes or replaces any non-alphabetical characters, including whitespaces, and capitalizes everything
 * @param {string} txt
 */
export function cleanText(txt) {
  let replaced = txt.replace(/à|â/g, "a");
  replaced = replaced.replace(/è|é|ê|ë/g, "e");
  replaced = replaced.replace(/î|ï/g, "i");
  replaced = replaced.replace(/ù|û/g, "u");
  replaced = replaced.replace(/ç/g, "c");
  replaced = replaced.replace(/æ|ä/g, "ae");
  replaced = replaced.replace(/œ|ö/g, "oe");
  replaced = replaced.replace(/ü/g, "ue");
  const removed = replaced.replace(/[^a-zA-Z]/g, "");
  return removed.toUpperCase();
}
