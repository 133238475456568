import React from "react";
import PropTypes from "prop-types";

import "./TheorySection.css";

/**
 * Renders one section on the theory page
 * @param {string} title
 * @param {string} description
 * @param {Array<string>} furtherTexts array of further paragraphs to render
 * @param {string} footText Text to display at end of the section after children
 * @param {object} insertComponent object with index of a paragraph and component to insert at beginning of that paragraph
 * @param {React.node} children components to render as part of section after furtherTexts
 */
function TheorySection({
  title,
  description,
  furtherTexts,
  footText,
  insertComponent,
  children,
}) {
  return (
    <div className="section">
      <h5>{title}</h5>
      <p>{description}</p>
      {furtherTexts.map((t, index) => {
        if (insertComponent !== undefined && insertComponent.index === index) {
          return (
            <div key={`${title}_subsection_${index.toString()}`}>
              {insertComponent.comp}
              <p>{t}</p>
            </div>
          );
        }
        return <p key={`${title}_subsection_${index.toString()}`}>{t}</p>;
      })}
      {children}
      <p>{footText}</p>
    </div>
  );
}

TheorySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  furtherTexts: PropTypes.arrayOf(PropTypes.string),
  footText: PropTypes.string,
  insertComponent: PropTypes.shape({
    index: PropTypes.number,
    comp: PropTypes.element,
  }),
  children: PropTypes.node,
};

TheorySection.defaultProps = {
  furtherTexts: [],
  footText: "",
  insertComponent: undefined,
  children: undefined,
};

export default TheorySection;
