import React, { useState, useRef } from "react";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Form,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import FCChartForLanguage from "../Statistics/FCChart/FCChartForLanguage";
import FCChartForText from "../Statistics/FCChart/FCChartForText";
import Statistics from "../Statistics/Statistics";
import EncryptionExample from "./EncryptionExample";
import TheorySection from "./TheorySection";

/**
 * Renders theory page.
 * Currently consists of the following sections:
 *  - Introtext
 *  - Abs. Frequency section
 *  - Rel. Frequency section
 *  - FC Section
 *      --> Possibility to try encrypting with different keys and compare results
 *      --> FC values for different languages
 */
function Theory() {
  const langsToMap = [
    { name: "Deutsch", lan: "DE", colour: "#B8A1FF" },
    { name: "Französisch", lan: "FR", colour: "#00B1B0" },
    { name: "Englisch", lan: "EN", colour: "#FF8370" },
  ];
  const [txt, setTxt] = useState("");
  const [keyLength, setKeyLength] = useState(0);
  const [displayError, setDisplayError] = useState(false);
  const overlayTarget = useRef();

  // TODO: Research how webpages usually handle texts, this is currently too much hassle to correct and maintain (think e.g. about possibility of translating to other languages)
  const introText =
    "Im Buch und in den hier gestellten Aufgaben werden mehrere verschiedene Verschlüsselungsmethoden vorgestellt. Besonders mono- und polyalphabetische Verschlüsselungen lassen sich fast nur über einen statistischen Ansatz lösen. An dieser Stelle möchten wir euch gerne eine kurze Übersicht über die verschiedenen hier verwendeten statistischen Konzepte und die damit verbunden Tools, welche euch zur Lösung der Aufgaben zur Verfügung gestellt werden, geben.";
  const absFreqDescription =
    "Im ersten Schritt der Analyse von verschlüsselten Texten misst man die absoluten Häufigkeiten aller alphabetischen Buchstaben im Text. Man erhält die absolute Häufigkeit eines Buchstabens, indem man zählt, wie oft der Buchstabe im Text vorkommt.";
  const absFreqText =
    "Die Berechnung der absoluten Häufigkeiten lässt sich relativ leicht automatisieren. Probiere es aus und teste dein Programm mit verschiedenen Texten!";
  const relFreqDescription =
    "Aus den erhaltenen absoluten Häufigkeiten lassen sich nun die entsprechenden relativen Häufigkeiten berechnen. Diese erhält man, indem man für jeden Buchstaben die absolute Häufigkeit durch die Länge des Textes teilt.";
  const relFreqText =
    "Jede Sprache hat eine eindeutige Verteilung der relativen Häufigkeiten, z.B. sticht im Deutschen das 'E' klar aus der Menge, wie man unten erkennt. Hiermit erhalten wir eine einfache Art um monoalphabetisch-verschlüsselte Texte zu entschlüsseln: Nämlich indem wir die relativen Häufigkeitsverteilungen vergleichen! Wenn wir wissen, in welcher Sprache der Klartext geschrieben wurde und er lang genug ist, müssen wir nur schauen, um wie viele Stellen sich die relativen Häufigkeiten verschoben haben. Sobald man die Anzahl Stellen berechnet hat, kann man den entsprechenden Buchstaben als Schlüssel angeben.";
  const relFreqSecondText =
    "Gebe nun unten einen Text ein, drücke auf 'Submit', und vergleiche die Ergebnisse mit den eingezeichneten relativen Häufigkeiten der deutschen Sprache.";
  const fcDescription =
    "Die oben beschriebene Methode zur Entschlüsselung von Geheimtexten lässt sich leider nur beschränkt bei polyalphabetisch-verschlüsselten Texten anwenden. Die relative Häufigkeitsverteilung sieht da nämlich ganz anders aus! Verschlüssle nun deinen Text mit einem mehrstelligen Schlüssel und füge diesen Geheimtext anschliessend oben ein, um zu sehen, wie sich die Verteilung ändert:";
  const fcFirstSection =
    "An dieser Stelle kommt die Friedmannsche Charakteristik ins Spiel. Wir fassen eine relative Häufigkeitsverteilung in eine Zahl, genannt die 'Friedmannsche Charakteristik', zusammen. Für verschiedene Schlüssellängen teilen wir den Text in Stücke auf, berechnen für jedes Stück die Friedmannsche Charakteristik, und schauen uns zum Schluss den Durchschnitt an (Im Buch findest du einen genaueren Beschrieb von diesem Prozess). Wenn der Durchschnitt nahe an der Friedmannschen Charakteristik der jeweiligen Sprache liegt, entscheiden wir uns für diese Schlüssellänge. Nun können wir uns die relativen Häufigkeiten der vorher erstellten Textstücke anschauen, um das Schlüsselwort zu berechnen.";
  const fcSecSection =
    "Probier's aus! Gib unten verschiedene Schlüssellängen ein und beobachte wie sich die Friedmannsche Charakteristik bewegt. Vergiss dabei nicht, dass die Friedmannsche Charakteristik für Vielfache der tatsächlichen Schlüssellänge auch 'richtig' aussieht.";
  const fcFoot =
    "Nun hast du alle Werkzeuge die du brauchst, um die im Buch vorgestellten Verschlüsselungsmethoden zu bewältigen. Gehe auf die Startseite und suche dir eine Aufgabe aus!";

  return (
    <Container fluid="lg">
      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem active>Theorie</BreadcrumbItem>
      </Breadcrumb>
      <p>{introText}</p>
      <TheorySection
        title="Absolute Häufigkeit"
        description={absFreqDescription}
        furtherTexts={[absFreqText]}
      />
      <TheorySection
        title="Relative Häufigkeit"
        description={relFreqDescription}
        furtherTexts={[relFreqText, relFreqSecondText]}
      >
        <Statistics />
      </TheorySection>
      <TheorySection
        title="Friedmannsche Charakteristik"
        description={fcDescription}
        furtherTexts={[fcFirstSection, fcSecSection]}
        footText={fcFoot}
        insertComponent={{
          index: 0,
          comp: <EncryptionExample setTxt={setTxt} />,
        }}
      >
        <Row>
          <Col sm={12} md={4}>
            <Form.Control
              size="md"
              type="number"
              min={1}
              max={5}
              ref={overlayTarget}
              placeholder="Schlüssellänge"
              onChange={handleKeyLengthChange(setKeyLength, setDisplayError)}
            />
            <Overlay
              target={overlayTarget.current}
              show={displayError}
              placement="bottom"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Bitte gebe eine Zahl zwischen 1 und 5 ein
                </Tooltip>
              )}
            </Overlay>
          </Col>
          <Col sm={12} md={8}>
            <FCChartForText
              statText={txt}
              keyLength={keyLength}
              colours={["#FEC84D"]}
            />
          </Col>
        </Row>
        <p>
          Die Friedmannsche Charakteristiken von Deutsch, Französisch und
          Englisch sind jeweils:
        </p>
        {langsToMap.map((v, index) => {
          return (
            <Row key={`${v.lan}_${index.toString()}`}>
              <Col sm={12} md={4}>
                {v.name}:{" "}
              </Col>
              <Col sm={12} md={8}>
                <FCChartForLanguage language={v.lan} colour={v.colour} />
              </Col>
            </Row>
          );
        })}
      </TheorySection>
    </Container>
  );
}

// handler functions
function handleKeyLengthChange(setKeyLength, setDisplayError) {
  return (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      setDisplayError(false);
      if (event.target.value !== "") {
        setKeyLength(parseInt(event.target.value, 10));
      }
    } else {
      setDisplayError(true);
    }
  };
}

export default Theory;
