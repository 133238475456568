/**
 * Relative letter frequencies per language.
 *
 *  - German: Taken from file "resources/language-frequencies/DeReChar-v-uni-030-b-l-2018-02-28-1.0.csv".
 *            Refer to https://www1.ids-mannheim.de/kl/projekte/methoden/derewo.html#derechar for more information.
 *  - French: Refer to http://practicalcryptography.com/cryptanalysis/letter-frequencies-various-languages/french-letter-frequencies/
 *  - English: Refer to http://practicalcryptography.com/cryptanalysis/letter-frequencies-various-languages/english-letter-frequencies/http://practicalcryptography.com/cryptanalysis/letter-frequencies-various-languages/english-letter-frequencies/
 *
 *  Note: Values were to normalized to account for language-specific letters (e.g. ä, é, etc.)
 */

export const data = {
  DE: [
    { letter: "a", freq: 0.061089494308 },
    { letter: "b", freq: 0.02184623478 },
    { letter: "c", freq: 0.027358686583 },
    { letter: "d", freq: 0.047984916342 },
    { letter: "e", freq: 0.16278462447 },
    { letter: "f", freq: 0.018635478201 },
    { letter: "g", freq: 0.03116394187 },
    { letter: "h", freq: 0.04322093827 },
    { letter: "i", freq: 0.078845068444 },
    { letter: "j", freq: 0.003028792271 },
    { letter: "k", freq: 0.015629773293 },
    { letter: "l", freq: 0.038516290531 },
    { letter: "m", freq: 0.028459189034 },
    { letter: "n", freq: 0.098251813081 },
    { letter: "o", freq: 0.027297891302 },
    { letter: "p", freq: 0.010675645938 },
    { letter: "q", freq: 0.000287716581 },
    { letter: "r", freq: 0.078694150386 },
    { letter: "s", freq: 0.064518994959 },
    { letter: "t", freq: 0.064776775692 },
    { letter: "u", freq: 0.038860066695 },
    { letter: "v", freq: 0.009344343099 },
    { letter: "w", freq: 0.01451830745 },
    { letter: "x", freq: 0.000525889345 },
    { letter: "y", freq: 0.001098247092 },
    { letter: "z", freq: 0.012586729983 },
  ],
  FR: [
    { letter: "a", freq: 0.07890365448504984 },
    { letter: "b", freq: 0.009966777408637873 },
    { letter: "c", freq: 0.03519518272425249 },
    { letter: "d", freq: 0.04235880398671096 },
    { letter: "e", freq: 0.15022840531561463 },
    { letter: "f", freq: 0.011627906976744186 },
    { letter: "g", freq: 0.012250830564784054 },
    { letter: "h", freq: 0.00965531561461794 },
    { letter: "i", freq: 0.07485465116279069 },
    { letter: "j", freq: 0.003114617940199336 },
    { letter: "k", freq: 0.0016611295681063125 },
    { letter: "l", freq: 0.06083887043189369 },
    { letter: "m", freq: 0.028862126245847178 },
    { letter: "n", freq: 0.07599667774086379 },
    { letter: "o", freq: 0.055959302325581405 },
    { letter: "p", freq: 0.030938538205980068 },
    { letter: "q", freq: 0.008824750830564785 },
    { letter: "r", freq: 0.07122093023255814 },
    { letter: "s", freq: 0.08284883720930232 },
    { letter: "t", freq: 0.07381644518272425 },
    { letter: "u", freq: 0.057620431893687714 },
    { letter: "v", freq: 0.013392857142857144 },
    { letter: "w", freq: 0.0008305647840531563 },
    { letter: "x", freq: 0.004464285714285714 },
    { letter: "y", freq: 0.0035299003322259138 },
    { letter: "z", freq: 0.0010382059800664453 },
  ],
  EN: [
    { letter: "a", freq: 0.08549145085491454 },
    { letter: "b", freq: 0.015998400159984005 },
    { letter: "c", freq: 0.03159684031596841 },
    { letter: "d", freq: 0.03869613038696131 },
    { letter: "e", freq: 0.12098790120987904 },
    { letter: "f", freq: 0.021797820217978207 },
    { letter: "g", freq: 0.020897910208979107 },
    { letter: "h", freq: 0.04959504049595042 },
    { letter: "i", freq: 0.07329267073292672 },
    { letter: "j", freq: 0.002199780021997801 },
    { letter: "k", freq: 0.008099190080991903 },
    { letter: "l", freq: 0.042095790420957915 },
    { letter: "m", freq: 0.025297470252974706 },
    { letter: "n", freq: 0.07169283071692832 },
    { letter: "o", freq: 0.07469253074692532 },
    { letter: "p", freq: 0.020697930206979305 },
    { letter: "q", freq: 0.0009999000099990003 },
    { letter: "r", freq: 0.06329367063293673 },
    { letter: "s", freq: 0.06729327067293273 },
    { letter: "t", freq: 0.08939106089391062 },
    { letter: "u", freq: 0.02679732026797321 },
    { letter: "v", freq: 0.010598940105989404 },
    { letter: "w", freq: 0.018298170182981705 },
    { letter: "x", freq: 0.0018998100189981007 },
    { letter: "y", freq: 0.017198280171982806 },
    { letter: "z", freq: 0.0010998900109989004 },
  ],
  default: [
    { letter: "a", freq: 0.01 },
    { letter: "b", freq: 0.01 },
    { letter: "c", freq: 0.01 },
    { letter: "d", freq: 0.01 },
    { letter: "e", freq: 0.01 },
    { letter: "f", freq: 0.01 },
    { letter: "g", freq: 0.01 },
    { letter: "h", freq: 0.01 },
    { letter: "i", freq: 0.01 },
    { letter: "j", freq: 0.01 },
    { letter: "k", freq: 0.01 },
    { letter: "l", freq: 0.01 },
    { letter: "m", freq: 0.01 },
    { letter: "n", freq: 0.01 },
    { letter: "o", freq: 0.01 },
    { letter: "p", freq: 0.01 },
    { letter: "q", freq: 0.01 },
    { letter: "r", freq: 0.01 },
    { letter: "s", freq: 0.01 },
    { letter: "t", freq: 0.01 },
    { letter: "u", freq: 0.01 },
    { letter: "v", freq: 0.01 },
    { letter: "w", freq: 0.01 },
    { letter: "x", freq: 0.01 },
    { letter: "y", freq: 0.01 },
    { letter: "z", freq: 0.01 },
  ],
};

export default data;
