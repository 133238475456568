import React from "react";
import PropTypes from "prop-types";

import { Card, Container, Row, Col, CardDeck } from "react-bootstrap";

import "./Navigation.css";

/**
 * Responsive navigational interface displaying each assignment as a Card component
 *  @param {Array} exercises array containing objects describing exercises
 */
export default function Navigation({ exercises }) {
  return (
    <nav>
      {/* Doesn't span full device width for lg or bigger */}
      <Container fluid="lg">
        {/* Cards are rendered with equal height and width, but aren't attached to one another */}
        <CardDeck>
          {/* Breakpoints set for responsiveness */}
          <Row xs={1} sm={2} md={2} lg={4} xl={4}>
            {exercises.map((exercise) => {
              let difficultyStars = "";
              for (let i = 0; i < exercise.difficulty; i += 1) {
                difficultyStars += "⭐";
              }
              return (
                <Col key={exercise.title}>
                  <Card bg="light" text="black" className="mx-auto">
                    <Card.Body>
                      <Card.Title>{exercise.title}</Card.Title>
                      <Card.Subtitle>{exercise.category}</Card.Subtitle>
                      <Card.Text style={{ paddingTop: "5px" }}>
                        {difficultyStars}
                      </Card.Text>
                      <Card.Link href={exercise.path}>Zur Aufgabe »</Card.Link>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </CardDeck>
      </Container>
    </nav>
  );
}

Navigation.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      difficulty: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      redirect: PropTypes.string.isRequired,
      // lazy import returns React.LazyExoticComponent of shape:
      component: PropTypes.shape({
        _payload: PropTypes.shape({
          _status: PropTypes.number.isRequired,
        }),
      }).isRequired,
    })
  ).isRequired,
};
