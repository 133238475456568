import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav } from "react-bootstrap";
import abzLogo from "./abz-web-logo.png";

/**
 * Implements a custom responsive navigation bar
 * @param {string} homeURL URL path to landing page
 * @param {string} exerciseURL URL path to exercise navigation page
 * @param {string} theoryURL URL path to theory page
 */
function CustomNavbar({ homeURL, exerciseURL, theoryURL }) {
  // width and height of the current viewport - updated on change
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  useEffect(() => {
    window.onresize = updateSize;
  }, []);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href={homeURL}>
        <img src={abzLogo} height="70" alt="ABZ Informatik" />
      </Navbar.Brand>
      {/* Hide title for xs devices, e.g. smartphone */}
      {size.x >= 768 && (
        <div className="justify-content-start" style={{ paddingBottom: "5px" }}>
          <Nav.Link href={homeURL}>
            <h3>Geheimschriften und Datensicherheit</h3>
          </Nav.Link>
        </div>
      )}
      <Navbar.Toggle />
      {/* Collapse navbar tabs behind a toggle on md devices or smaller */}
      <Navbar.Collapse className="justify-content-end">
        <Nav className="justify-content-end">
          <Nav.Link href={exerciseURL}>Aufgaben</Nav.Link>
          <Nav.Link href={theoryURL}>Theorie</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

CustomNavbar.propTypes = {
  homeURL: PropTypes.string.isRequired,
  exerciseURL: PropTypes.string.isRequired,
  theoryURL: PropTypes.string.isRequired,
};

export default CustomNavbar;
