import { relativeFrequencyAll, statRelativeFrequency } from "./statistics-util";

export const relFreqName = "Rel. Häufigkeit";
export function getNameWithIndex(index) {
  return `${relFreqName} T${index.toString()}`;
}
function getNameAsRegex(startIndex, endIndex) {
  return new RegExp(`${relFreqName} T[${startIndex}-${endIndex}]`);
}

/**
 * Update an array of relative frequency objects: Replace all relative frequencies in target
 * that are also given in source, if the letter is present. If it isn't just add the entire
 * source object.
 * Note that 'replacing all relative frequencies' involves a cleanup to ensure clean naming of
 * legend items, i.e. no duplicates, none missing and understandable naming
 * @param {Array} target Current array of relative frequencies
 * @param {Array} source Values to update
 * @returns {Array} updated array of relative frequencies
 */
export function mergeByProperty(target, source) {
  source.forEach((sourceElement) => {
    // Finds object with same letter field
    const targetElement = target.find((elem) => {
      return sourceElement.letter === elem.letter;
    });
    if (targetElement) {
      cleanUnusedElements(sourceElement, targetElement);
      Object.assign(targetElement, sourceElement);
    } else {
      target.push(sourceElement);
    }
  });
  return target;
}

function cleanUnusedElements(sourceElement, targetElement) {
  if (getNameWithIndex(0) in sourceElement) {
    // Keylength > 1
    /* eslint no-param-reassign: "off" */
    delete targetElement[relFreqName]; // Ensure "Rel. Häufigkeit" is removed when switching to keyLength > 1

    // Find updated keyLength
    const maxInt = Object.keys(sourceElement).reduce((max, curr) => {
      const curInt = parseInt(curr.slice(-1), 10);
      if (getNameAsRegex(0, 9).test(curr) && curInt > max) {
        return curInt;
      }
      return max;
    }, 0);

    Object.keys(targetElement)
      .filter((el) => getNameAsRegex(0, 9).test(el))
      .forEach((key) => {
        if (parseInt(key.slice(-1), 10) > maxInt) {
          // Ensure T_i for i >= keyLength are removed
          delete targetElement[key];
        }
      });
  } else if (relFreqName in sourceElement) {
    // Keylength <= 1
    const propsToDelete = Object.keys(targetElement).filter((key) =>
      getNameAsRegex(0, 9).test(key)
    );
    propsToDelete.forEach((key) => {
      // Ensure we only have "Rel. Häufigkeit" in updated legend
      delete targetElement[key];
    });
  }
}

/**
 * Wrapper around statRelativeFrequency to rename fields as given in
 * language-frequencies file to fit legend item naming
 * @param {string} language relative frequencies to get
 * @returns {Array} renamed relative frequency array
 */
export function getLangStatsWithRenamedFields(language) {
  try {
    return statRelativeFrequency(language).map((elem) => {
      const suffix = language === "default" ? "" : ` ${language}`;
      return renameField(elem, "freq", relFreqName + suffix);
    });
  } catch (e) {
    return [];
  }
}

/**
 * Wrapper around relativeFrequencyAll to rename fields to fit
 * legend item naming
 * @param {string} inputText text to compute rel frequencies for
 * @param {number} keyLength keylength to compute rel frequencies with
 * @returns {Array} renamed relative frequency array
 */
export function getRelFreqWithRenamedFields(inputText, keyLength) {
  if (parseInt(keyLength, 10) === 0 || parseInt(keyLength, 10) === 1) {
    return relativeFrequencyAll(inputText, 1).map((elem) => {
      return renameField(elem, "freq_0", relFreqName);
    });
  }
  return relativeFrequencyAll(inputText, keyLength).map((elem) => {
    for (let i = 0; i < keyLength; i += 1) {
      elem = renameField(elem, `freq_${i}`, getNameWithIndex(i));
    }
    return elem;
  });
}

function renameField(elem, from, to) {
  elem[to] = elem[from];
  delete elem[from];
  return elem;
}
