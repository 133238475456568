import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

/**
 * Renders a horizontal barchart to graphically display fc chart
 * @param {number} fcData FC value for which to render chart
 * @param {Array<string>} colours colour[0] is used to render chart
 */
function FCChart({ fcData, colours }) {
  // Note: Finding correct padding and margin settings is a bit fiddly
  // --> Care with changing these numbers, try for different device sizes
  return (
    <ResponsiveContainer width="100%" height={50}>
      <BarChart
        data={[{ fc: fcData }]}
        layout="vertical"
        height={40}
        margin={{
          top: 5,
          right: 5,
          bottom: 10,
          left: 5,
        }}
      >
        <Tooltip
          formatter={(value) => value.toFixed(4)}
          labelFormatter={() => ""}
          allowEscapeViewBox={{ x: true, y: false }}
          offset={30}
          animationDuration={500}
        />
        <XAxis
          type="number"
          height={10}
          tickSize={3}
          tickMargin={3}
          domain={[0.0, 0.1]}
          scale="linear"
        />
        <YAxis type="category" padding={{ bottom: -20 }} hide />
        <Bar
          dataKey="fc"
          minPointSize={3}
          name="Friedmannsche Charakteristik"
          fill={colours[0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

FCChart.propTypes = {
  fcData: PropTypes.number.isRequired,
  colours: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FCChart;
