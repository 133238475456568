/**
 * Module providing utility functions for computing statistical values.
 * Usually, one would only need to use one of the following three functions:
 *  - relativeFrequencyAll
 *  - fcForKeyLength
 *  - statRelativeFrequency
 */

import Data from "./language-frequencies";
import { getClearTextChar } from "../../Assignments/assignments-util/encryption-util";

/**
 * Computes the number of times a character appears in a text
 * @param {String} letter
 * @param {String} text
 * @returns {Number} absolute frequency of letter in text
 */
export function absoluteFrequency(letter, text) {
  if (text.length < 1) {
    return 0.0;
  }
  if (!/[A-Za-z]/.test(letter) || letter.length !== 1) {
    throw new Error(`letter ${letter} is not a valid alphabetical character!`);
  }

  const toKeep = new RegExp(`[^${letter.toLowerCase()}]`, "g");
  const numOccurences = text.toLowerCase().replace(toKeep, "").length;
  return numOccurences;
}

/**
 * Computes the fraction of the text that consists of a given character
 * @param {String} letter
 * @param {String} text
 * @returns {Number} relative frequency of letter in text
 */
export function relativeFrequency(letter, text) {
  if (text.length < 1) {
    return 0.0;
  }
  if (!/[A-Za-z]/.test(letter) || letter.length !== 1) {
    throw new Error(`letter ${letter} is not a valid alphabetical character!`);
  }
  const textTrimmed = text.match(/[A-Za-z]/g);
  if (!textTrimmed || !textTrimmed.length > 0) {
    return 0.0;
  }

  const numOccurences = absoluteFrequency(
    letter.toLowerCase(),
    text.toLowerCase()
  );

  return numOccurences / textTrimmed.length;
}

/**
 * Computes the relative frequencies for all alphabetical characters
 * @param {String} text
 * @returns {Array} An array of objects, each with a letter and a freq field
 */
export function relativeFrequencyAll(text, keyLength = 1) {
  const txtGroups = modSplit(keyLength < 1 ? 1 : keyLength, text);
  return getAlphabet().map((letter) => {
    return txtGroups.reduce(
      (o, txt, index) => ({
        ...o,
        [`freq_${index}`]: relativeFrequency(letter, txt),
      }),
      { letter }
    );
  });
}

/**
 * Computes the expected relative frequency of a character in a ciphertext
 * generated by a given key
 * @param {String} letter
 * @param {String} key
 * @returns {Number} expected relative frequency of letter when encrypting a text with key
 */
export function expectedRelativeFrequency(letter, key, language) {
  if (key.length < 1) {
    return 0.0;
  }
  if (!/[A-Za-z]/.test(letter) || letter.length !== 1) {
    throw new Error(`Illegal letter ${letter}`);
  }
  const frequencies = statRelativeFrequency(language);
  const totalFreq = key.split("").reduce((f, k) => {
    return (
      f +
      frequencies.find(
        (val) => val.letter === getClearTextChar(letter.toLowerCase(), k)
      ).freq
    );
  }, 0.0);
  return totalFreq / key.length;
}

/**
 * Returns the relative frequencies of the letters in that language.
 * Note that only letters actually part of that language's alphabet
 * are considered. All others will return undefined.
 * @param {String} language
 * @return {Array} COPY of the object representing the relative frequencies
 */
export function statRelativeFrequency(language) {
  switch (language) {
    case "DE":
      return JSON.parse(JSON.stringify(Data.DE));
    case "FR":
      return JSON.parse(JSON.stringify(Data.FR));
    case "EN":
      return JSON.parse(JSON.stringify(Data.EN));
    case "default":
      return JSON.parse(JSON.stringify(Data.default));
    default:
      throw new Error(`Uknown language ${language}`);
  }
}

export function getAlphabet() {
  /* eslint-disable func-names */
  const iterate = function* (a, b) {
    for (let i = a; i <= b; i += 1) {
      yield i;
    }
  };
  return [...iterate("a".charCodeAt(), "z".charCodeAt())].map((n) =>
    String.fromCharCode(n)
  );
}

/**
 *
 * @param {String} text only letters in range [a-zA-Z] are considered
 * @returns {Number} friedman's characteristic of the given text
 */
export function friedmansCharacteristic(text) {
  if (text.length < 1) {
    return 0.0;
  }
  const relativeFrequencies = getAlphabet().map(
    (letter) => (relativeFrequency(letter, text.trim()) - 1 / 26) ** 2
  );
  const fc = relativeFrequencies.reduce((a, b) => {
    return a + b;
  }, 0.0);
  return fc;
}

/**
 * Seperates a text into keyLength slices, computes their respective FC's and
 * returns the average value
 *
 * @param {Number} keyLength
 * @param {String} text
 * @returns {Number} the average FC of each textslice
 */
export function fcForKeyLength(keyLength, text) {
  if (text.length < 1) {
    return 0.0;
  }
  if (keyLength === 0) {
    return 0.0;
  }
  if (keyLength < 0) {
    throw new Error("Expected non-negative keylength");
  }

  const slices = modSplit(keyLength, text);

  const fcVals = slices.map(friedmansCharacteristic);
  const average = (array) => array.reduce((a, b) => a + b) / array.length;
  const fcRes = average(fcVals);
  return fcRes;
}

/**
 * Splits a text into slices T_i
 * Character c_j at position j in text is added to slice j%keyLength
 * @param {number} keyLength
 * @param {string} text
 * @returns {Array<string>} array of keyLength textslices T_i
 */
function modSplit(keyLength, text) {
  if (keyLength < 1) {
    throw new Error(
      "KeyLength passed to modSplit was non-positive: %i",
      keyLength
    );
  }
  let slices = new Array(keyLength);
  slices.length = keyLength;
  slices = slices.fill("", 0, slices.length);
  const txt = text.replace(/\s/g, "").split("");

  const modConcat = (elem, index) => {
    let str = "";
    for (let i = index; i < txt.length; i += slices.length) {
      str += txt[i];
    }
    return str;
  };
  slices = slices.map(modConcat);
  return slices;
}
